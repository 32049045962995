<template>
  <div class="row mx-0">
    <div class="col-12 p-0" v-if="debitOrders && debitOrders.length > 0">
      <!-- TABLE HEADING -->
      <div class="row m-0">
        <div class="col-12 px-0">
          <!--DESKTOP-->
          <div class="row font13 green-text-dark d-none d-md-flex">
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-left">
                REFERENCE
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                FREQUENCY
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                PAYMENT METHOD
              </div>
            </div>
            <div class="col-md bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                START DATE
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                AMOUNT
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                TOOLS
              </div>
            </div>
          </div>
          <!--END DESKTOP-->
          <!--MOBILE-->
          <div class="row font13 green-text-dark d-md-none">
            <div class="col-3 bold text-center px-0">
              Tran. Ref.
            </div>
            <div class="col-3 bold text-center px-0">
              Start Date
            </div>
            <div class="col-3 bold text-center px-0">
              Amount
            </div>
            <div class="col-3 bold text-center px-0">
              Info
            </div>
          </div>
          <!--END MOBILE-->
        </div>
      </div>
      <!-- END TABLE HEADING -->
      <RecurringDonationItem v-for="(donation, key) in debitOrders.slice(start, finish)" :key="key" :item="donation" :thekey="key" :isReady="isReady" @showDetails="showDetails(key)" @print="print(donation.debitOrderID)" @deleteOrder="deleteOrder(donation.debitOrderID)" />
      <transition-group name="slideup">
        <RecurringDonationViewEditPopup v-if="currentItemDetails && isEdit" :item="currentItemDetails" @close="closeEdit" key="edit"  />
        <RecurringDonationEditBankPopup v-else-if="currentItemDetails && isEditBank" :item="currentItemDetails" @close="closeEditBank" key="editBank"  />
        <RecurringDonationViewPopup v-else-if="currentItemDetails" :item="currentItemDetails" @close="closeDetails" @edit="editDebit" @editBank="editDebitBank" key="view" />
      </transition-group>
      <!-- PAGINATION -->
      <div class="row mx-0 mt-5" v-if="totalPages > 1">
        <div class="col-12 text-center">
          <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
            {{ index }}
          </div>
        </div>
      </div>
      <!-- END PAGINATION -->
    </div>
    <div class="col-12 p-0 text-center" v-else>
      You don't have any Recurring Donations, set some up.
    </div>
    <transition name="fade">
      <Popup @close="close" v-if="isDeleteDebit">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            Cancel Debit Order {{currentID}}
          </div>
          <div class="col-12 text-center mb-3">
            Are you sure you would like to cancel this debit order?
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="grey" btnText="Keep Debit Order" width="100" @buttonClicked="isDeleteDebit = false" />
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="red" btnText="Cancel Debit Order" width="100" @buttonClicked="reallyDelete" />
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    Popup: defineAsyncComponent(() => import('./Popup.vue')),
    RecurringDonationEditBankPopup: defineAsyncComponent(() => import('./RecurringDonationEditBankPopup.vue')),
    RecurringDonationItem: defineAsyncComponent(() => import('./RecurringDonationItem.vue')),
    RecurringDonationViewEditPopup: defineAsyncComponent(() => import('./RecurringDonationViewEditPopup.vue')),
    RecurringDonationViewPopup: defineAsyncComponent(() => import('./RecurringDonationViewPopup.vue'))
  },
  name: 'RecurringDonationsTable',
  props: ['transactionSearch'],
  data () {
    return {
      projectType: null,
      startDate: null,
      endDate: null,
      priceRange: null,
      donationType: null,
      itemsPerPage: 10,
      currentPage: 0,
      currentItem: null,
      currentKey: null,
      currentItemDetails: null,
      isDeleteDebit: false,
      currentID: null,
      isEdit: false,
      isEditBank: false,
      isReady: false
    }
  },
  watch: {
    transactionSearch () {
      this.currentPage = 0
    },
    currentItem: {
      deep: true,
      handler (val) {
        if (this.currentItem) {
          this.getCurrentDetails(this.currentItem.debitOrderID)
        }
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'debitOrders']),
    totalPages () {
      return Math.ceil(this.debitOrders.length / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    }
  },
  methods: {
    ...mapActions(['printTransactionReceipt', 'stopDebitOrder', 'fetchDebitOrderDetails', 'fetchUserDebitOrders']),
    goToPage (val) {
      this.currentPage = val - 1
      this.$emit('goToDonations')
    },
    updateDate (val) {
      if (val) {
        const handler = {
          get (target, property) {
            return target[property]
          }
        }
        const proxyVal = new Proxy(val, handler)
        this.startDate = proxyVal.startDate
        this.endDate = proxyVal.endDate
      } else {
        this.startDate = null
        this.endDate = null
      }
    },
    updatePrice (val) {
      if (val === null) {
        this.priceRange = null
      } else {
        this.priceRange = val
      }
    },
    updateDonationType (val) {
      if (val === null) {
        this.donationType = null
      } else {
        this.donationType = val
      }
    },
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    },
    showDetails (val) {
      this.isReady = false
      this.currentKey = this.start + val
      this.currentItem = this.debitOrders[this.currentKey]
    },
    getCurrentDetails (val) {
      this.fetchDebitOrderDetails(val).then((result) => {
        this.currentItemDetails = result.data
        this.isReady = true
      })
    },
    deleteOrder (val) {
      this.currentID = val
      this.isDeleteDebit = true
    },
    async reallyDelete () {
      await this.stopDebitOrder(this.currentID)
      this.close()
    },
    async print (val) {
      await this.printTransactionReceipt(val)
    },
    goForward (val) {
      this.currentItem = this.debitOrders[val + 1]
      this.currentKey = val + 1
    },
    goBack (val) {
      this.currentItem = this.debitOrders[val - 1]
      this.currentKey = val - 1
    },
    close () {
      this.isDeleteDebit = false
      this.currentID = null
      this.fetchUserDebitOrders()
    },
    closeDetails () {
      this.currentItem = null
      this.currentKey = null
      this.currentItemDetails = null
    },
    async closeEdit () {
      await this.getCurrentDetails(this.currentItem.debitOrderID)
      this.isEdit = false
    },
    async closeEditBank () {
      await this.getCurrentDetails(this.currentItem.debitOrderID)
      this.isEditBank = false
    },
    editDebit () {
      this.isEdit = true
    },
    editDebitBank () {
      this.isEditBank = true
    }
  }
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
@media (min-width: 992px) {
  .rounded-left {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }
  .rounded-right {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
